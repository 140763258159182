//GOOGLE Analytics Events
$(document).ready(function() {
    //--- Sitewide
    $(".navbar-tel").click(function() {
        console.log('Leads', 'Contact', 'Phone');
        ga('send', 'event', 'Leads', 'Contact', 'Phone', 1);
    });
    $(".nav-tel").click(function() {
        console.log('Leads', 'Contact', 'Phone');
        ga('send', 'event', 'Leads', 'Contact', 'Phone', 1);
    });
    $(".nav-email").click(function() {
        console.log('Leads', 'Contact', 'Email');
        ga('send', 'event', 'Leads', 'Contact', 'Email', 1);
    });
    $(".nav-map").click(function() {
        console.log('Leads', 'Locate', 'Map');
        ga('send', 'event', 'Leads', 'Locate', 'Map', 1);
    });
    //footer
    $(".custom-html-widget > .action_phone").click(function() {
        console.log('Leads', 'Contact', 'Phone');
        ga('send', 'event', 'Leads', 'Contact', 'Phone', 1);
    });
    $(".custom-html-widget > .action_email").click(function() {
        console.log('Leads', 'Contact', 'Email');
        ga('send', 'event', 'Leads', 'Contact', 'Email', 1);
    });
    $(".custom-html-widget > .action_map").click(function() {
        console.log('Leads', 'Locate', 'Map');
        ga('send', 'event', 'Leads', 'Locate', 'Map', 1);
    });

    //external links
    $(".login-resident").click(function() {
        console.log('Links', 'Opened', 'Tenant Login');
        ga('send', 'event', 'Links', 'Opened', 'Tenant Login', 1);
    });
    $(".action_tenant").click(function() {
        console.log('Links', 'Opened', 'Tenant Login');
        ga('send', 'event', 'Links', 'Opened', 'Tenant Login', 1);
    });
    $(".login-owner").click(function() {
        console.log('Links', 'Opened', 'Owner Login');
        ga('send', 'event', 'Links', 'Opened', 'Owner Login', 1);
    });
    $(".login-apply").click(function() {
        console.log('Leads', 'Opened', 'Apply Online');
        ga('send', 'event', 'Leads', 'Opened', 'Apply Online', 1);
    });

    //--- Resources
    $(".action_resource").click(function() {
        console.log('Resources', 'Opened', 'Resource PDF');
        ga('send', 'event', 'Resources', 'Opened', 'Application PDF', 1);
    });

    //--- Action Buttons
    //Qualify resources
    $(".action_step > .action_screening").click(function() {
        console.log('Resources', 'Opened', 'Screening PDF');
        ga('send', 'event', 'Resources', 'Opened', 'Screening PDF', 1);
    });
    $(".action_step > .action_application").click(function() {
        console.log('Resources', 'Opened', 'Application PDF');
        ga('send', 'event', 'Resources', 'Opened', 'Application PDF', 1);
    });
    //Schedule Online
    $(".schedule-wrap > a").click(function() {
        console.log('Leads', 'Opened', 'Schedule Online');
        ga('send', 'event', 'Leads', 'Opened', 'Schedule Online', 1);
    });
    $(".action_step > .action_phone").click(function() {
        console.log('Leads', 'Contact', 'Phone');
        ga('send', 'event', 'Leads', 'Contact', 'Phone', 1);
    });
    $(".action_step > .action_email").click(function() {
        console.log('Leads', 'Contact', 'Email');
        ga('send', 'event', 'Leads', 'Contact', 'Email', 1);
    });
    //Apply Online
    $(".action_apply_online").click(function() {
        console.log('Leads', 'Opened', 'Apply Online');
        ga('send', 'event', 'Leads', 'Opened', 'Apply Online', 1);
    });
    $(".action_step > .action_map").click(function() {
        console.log('Leads', 'Locate', 'Map');
        ga('send', 'event', 'Leads', 'Locate', 'Map', 1);
    });

    //--- Contact Forms
    //links
    $(".action_contact > .action_phone").click(function() {
        console.log('Leads', 'Contact', 'Phone');
        ga('send', 'event', 'Leads', 'Contact', 'Phone', 1);
    });
    $(".action_contact > .action_email").click(function() {
        console.log('Leads', 'Contact', 'Email');
        ga('send', 'event', 'Leads', 'Contact', 'Email', 1);
    });

});